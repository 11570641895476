import styled, { css } from 'styled-components';

export const SelectWrapper = styled.div`
  button {
    border-color: #ddd;
    width: 100%;

    & > div {
      height: 35px;
    }
    input {
      font-weight: normal;
    }
  }
`;
export const DateFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e5e7eb;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
  label {
    margin: 0 !important;
  }
`;

export const FieldWrapper = styled.div`
  .form-input {
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba(${(props) => props.theme.brandRbgColor}, 0.5);
    }
  }
`;

export const InputLabel = styled.label`
  display: block;
  font-size: 0.75rem;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 1;
`;

export const Input = styled.input`
  border: 1px solid #ddd;
  background: white;
  padding: 16px;
  height: 50px;
  display: inline-flex;
  width: 100%;
  color: #222;
  border-radius: 5px;

  &:-internal-autofill-selected {
    background-color: #ededed !important;
  }

  &:focus {
    outline: none;
    border-color: ${(props) => `rgba(${props.theme.brandRbgColor},0.9);`};
  }

  &::placeholder {
    font-weight: 300;
    letter-spacing: 0.5px;
    color: #828282;
  }
`;
export const InputError = styled.div`
  color: red;
  font-size: 13px;
`;

// Button elements
export const IconButton = styled.button`
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid #ddd;
  background-color: white;

  &:hover {
    background-color: #444444;
    color: #ededed;
  }

  svg {
    width: 32px;
  }
`;

export const ButtonStyle = css`
  border: 1px solid #ddd;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75em 1em;
  background: ${(props) => props.theme.brandColor};
  color: white;
  font-weight: 600;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.4s;

  svg {
    margin-right: 0.5em;
    fill: white;
    width: 24px !important;
    height: auto !important;
  }

  &.mutation {
    font-size: 1.25rem;
    svg {
      width: 32px !important;
    }
  }

  &:disabled {
    color: #e6e6e6;
    background: rgba(${(props) => props.theme.brandRbgColor}, 0.7);
    cursor: not-allowed;

    svg {
      fill: #e6e6e6;
    }
  }

  &.delete {
    border: 1px solid #a51111;
    background-color: transparent;
    color: #cb1919;

    &:hover {
      background-color: #a51111;
      color: white;
    }
  }

  &.clean {
    background: transparent;
    border-color: transparent;
    color: #222;
  }
`;
export const ActionOneButton = styled.button`
  ${ButtonStyle}
`;
export const CancelButton = styled.button`
  ${ButtonStyle}
  background: #f34848;
  color: white;
  font-size: 20px;

  svg {
    margin-right: 5px;
    fill: white;
  }

  &:disabled {
    color: #e6e6e6;
    background: #e28d8d;
    cursor: not-allowed;

    svg {
      fill: #e6e6e6;
    }
  }
`;
export const FilterButton = styled.button`
  border: 2px solid #ccc;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.6em 1.25em;
  margin-right: 2em;
  color: #929292;
  height: 40px;

  svg {
    margin-right: 1em;
  }

  &.active {
    border: 2px solid ${(props) => props.theme.brandColor};
    color: ${(props) => props.theme.brandColor};
    font-weight: 700;
  }
`;

export const Toolbar = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1.75em;
`;

// Card styles
export const Card = styled.div`
  margin-bottom: 2em;
  padding: 0 2em;

  &.mutation-form {
    box-shadow: 1px 1px 30px -4px rgba(0, 0, 0, 0.1);
    background: white;

    .formActions {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 1em 0 2em;
    }
  }
`;
export const CardHeader = styled.div`
  // border-bottom: 1px solid #e2e8f0;
  display: flex;
  align-items: center;
  padding: 1.75em 0 1em;
`;
export const CardTitle = styled.div`
  font-size: 1.125rem;
  font-weight: 700;
  display: inline-flex;
  color: #444444;
`;

// Page styles
export const PageHeader = styled.div`
  display: flex;
  align-items: center;
  margin: 2em;
  border-bottom: 1px solid #ddd;
  padding-bottom: 2em;
  line-height: 1.4;

  a {
    color: #424242;
    font-size: 1.1rem;
  }

  .activeNavItem {
    font-weight: bold;
  }
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
export const PageTitle = styled.div`
  font-size: 1.75rem;
  font-weight: 700;
  display: inline-flex;
  letter-spacing: -1px;
  color: #444444;
`;
export const PageActions = styled.div`
  margin-left: auto;
`;

export const Title = styled.div`
  font-size: 1.125rem;
  font-weight: 700;
`;

export const Table = styled.table`
  width: 100%;
  color: #1b1b1b;
  font-size: 14px;
  background: white;

  th {
    text-align: left;
  }

  th,
  td {
    padding: 1em;
    border-bottom: 1px solid #ededed;

    &.photo {
      padding: 0.5em;
      width: 90px;
      height: 60px;
    }

    &.date {
      width: 167px;
    }

    &.account {
      min-width: 160px;
      max-width: 180px;
    }

    &.hxUid {
      min-width: 110px;
    }
  }

  tr {
    &.expanded-row {
      background: #e6e6e6;

      & > td {
        vertical-align: top;
        padding: 0;
      }
    }
    &.expanded {
      background: #dddede;
    }
  }

  &.fiat {
    .actionButton {
      svg {
        fill: #ddd;
      }
    }

    .process-row {
      & > div {
        padding: 2em;
      }
    }

    .process-col {
      width: 60%;
    }

    .cancel-col {
      width: 40%;
      border-left: 5px solid #dddede;
    }
  }
`;


export const Wrapper = styled.div`
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  background-color: #ffffff;
  box-shadow: 5px 5px 10px #0000000d;
  max-width: 100%;
  opacity: 1;
  padding: 20px 20px;
  margin: 0 10px;
  width: 100%;
  @media (min-width: 500px) {
    padding: 30px 50px;
  }
  @media (min-width: 768px) {
    padding: 50px 80px;
  }
  @media (min-width: 1536px) {
    padding: 30px 50px;
  }
`;

export const WrapperTitle = styled.div`
  display: flex;
  justify-content: start;
  padding-top: 30px;
  padding-bottom: 30px;
`;

export const WrapperForm = styled.form`
  margin-top: 20px;
  @media (min-width: 500px) {
    margin-top: 30px;
  }
  @media (min-width: 1024px) {
    margin-top: 40px;
  }
  @media (min-width: 1280px) {
    margin-top: 50px;
  }
  @media (min-width: 1536px) {
    margin-top: 60px;
  }
`;

export const WrapperInput = styled.div`
  margin-top: 10px;
  @media (min-width: 500px) {
    margin-top: 12px;
  }
  @media (min-width: 1024px) {
    margin-top: 14px;
  }
  @media (min-width: 1280px) {
    margin-top: 16px;
  }
  @media (min-width: 1536px) {
    margin-top: 20px;
  }
`;

export const WrapperPhone = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: end;
  gap: 6px;
  @media (min-width: 500px) {
    gap: 7px;
    margin-top: 12px;
  }
  @media (min-width: 1024px) {
    gap: 8px;
    margin-top: 14px;
  }
  @media (min-width: 1280px) {
    gap: 9px;
    margin-top: 16px;
  }
  @media (min-width: 1536px) {
    gap: 10px;
    margin-top: 20px;
  }
`;